<template>
  <b-row class='mt-3'>
    <b-col cols='12'>
      <b-card>
        <b-row>
          <b-col cols='3'>
            <b>Connection Key:</b>
          </b-col>
          <b-col cols='9'>
            <b-form-group
              :invalid-feedback="handleError('configs.connection_key')"
              :state="isValid('configs.connection_key')">
              <b-form-input v-model='configs.connection_key' :disabled='!editable' />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class='mt-2'>
          <b-col cols='3'>
            <b>Hostname:</b>
          </b-col>
          <b-col cols='9'>
            <b-form-group
              :invalid-feedback="handleError('configs.hostname')"
              :state="isValid('configs.hostname')">
              <b-form-input v-model='configs.hostname' :disabled='!editable' />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class='mt-2'>
          <b-col cols='3'>
            <b>Port:</b>
          </b-col>
          <b-col cols='9'>
            <b-form-group
              :invalid-feedback="handleError('configs.port')"
              :state="isValid('configs.port')">
              <b-form-input v-model='configs.port' :disabled='!editable' />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class='mt-2'>
          <b-col cols='3'>
            <b>Username:</b>
          </b-col>
          <b-col cols='9'>
            <b-form-group
              :invalid-feedback="handleError('configs.username')"
              :state="isValid('configs.username')">
              <b-form-input v-model='configs.username' :disabled='!editable' />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class='mt-2'>
          <b-col cols='3'>
            <b>Use Socks Proxy</b>
          </b-col>
          <b-col cols='9'>
            <b-form-group>
              <b-form-checkbox v-model='configs.use_socks_proxy' :disabled='!editable' />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class='mt-2'>
          <b-col cols='3'>
            <b>Login Type:</b>
          </b-col>
          <b-col cols='9'>
            <b-form-group>
              <b-form-select v-model='configs.login_type' :options='loginTypes' :disabled='!editable' />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class='mt-2' v-if='configs.login_type === "password"'>
          <b-col cols='3'>
            <b>Password:</b>
          </b-col>
          <b-col cols='9'>
            <b-form-group
              :invalid-feedback="handleError('configs.password')"
              :state="isValid('configs.password')">
              <b-row>
                <b-col cols="11">
                  <b-form-input v-if="showPassword" v-model='configs.password' :disabled='!editable' />
                  <b-form-input v-else type="password" v-model='configs.password' :disabled=true />
                </b-col>
                <b-col cols="1">
                  <b-button
                    class='float-right'
                    @click='showPassword = !showPassword'>
                    {{ showPassword ? 'Hide' : 'Show' }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class='mt-2' v-if='configs.login_type === "public_key"'>
          <b-col cols='3'>
            <b>SSH Key:</b>
          </b-col>
          <b-col cols='9'>
            <b-form-group
              :invalid-feedback="handleError('configs.ssh_key')"
              :state="isValid('configs.ssh_key')">
              <b-textarea v-model='configs.ssh_key' :disabled='!editable' />
            </b-form-group>
          </b-col>
          <b-col cols='3'>
            <b>SSH password:</b>
          </b-col>
          <b-col cols='9'>
            <b-form-group
              :invalid-feedback="handleError('configs.ssh_password')"
              :state="isValid('configs.ssh_password')">
              <b-row>
                <b-col cols="11">
                  <b-form-input v-if="showSshPassword" v-model='configs.ssh_password' :disabled='!editable' />
                  <b-form-input v-else type="password" v-model='configs.ssh_password' :disabled=true />
                </b-col>
                <b-col cols="1">
                  <b-button
                    class='float-right'
                    @click='showSshPassword = !showSshPassword'>
                    {{ showSshPassword ? 'Hide' : 'Show' }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class='mt-2'>
          <b-col cols='3'>
            <b>Created At:</b>
          </b-col>
          <b-col cols='9'>
            <span>{{ dateNormalizer(configs.created_at) }}</span>
          </b-col>
        </b-row>
        <b-row class='mt-2'>
          <b-col cols='3'>
            <b>Updated At:</b>
          </b-col>
          <b-col cols='9'>
            <span>{{ dateNormalizer(configs.updated_at) }}</span>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment/moment';
import { required, requiredIf } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';

export default {
  name: 'SFTPRemoteServerDetails',
  mixins: [ errorHandler ],
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    configs: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    configs: {
      handler() {
        this.triggerValidation();
      },
      deep: true,
    },
  },
  data: () => ({
    loading: false,
    showPassword: false,
    showSshPassword: false,
    loginTypes: [
      { value: 'password', text: 'Password' },
      { value: 'public_key', text: 'Public Key' },
    ],
  }),
  mounted() {
    this.triggerValidation();
  },
  validations() {
    return {
      configs: {
        connection_key: {
          required,
        },
        hostname: {
          required,
        },
        port: {
          required,
        },
        username: {
          required,
        },
        password: {
          required: requiredIf(value => value.login_type === 'password'),
        },
        ssh_key: {
          required: requiredIf(value => value.login_type === 'public_key'),
        },
        ssh_password: {
          required: requiredIf(value => value.login_type === 'public_key'),
        },
      },
    };
  },
  methods: {
    dateNormalizer(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    triggerValidation() {
      if (this.$v.$invalid) {
        this.$emit('form-validation', true);
      } else {
        this.$emit('form-validation', false);
      }
    },
  },
};
</script>
